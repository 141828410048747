<template>

    <div class="container mb-3">
        <div class="row">
            <div class="row">
                <div class="col-md-6">
                    <h3>Vendor Master</h3>
                </div>
                <div class="col-md-6">
                    <!-- <pre>{{ form }} {{ query }}</pre> -->
                    <button class="btn btn-primary " v-if="!seen" @click="seen = !seen" style="float: right;"
                        id="btnAdd">
                        <i class="fa fa-plus-circle" aria-hidden="true"></i> Add Vendor</button>
                    <button class="btn btn-primary" v-if="seen" @click="seen = !seen" style="float: right;"
                        id="btnHide">
                        <i class="fa fa-minus-circle" aria-hidden="true"></i>
                        Hide</button>
                </div>
            </div>

        </div>

        <div class="row" id="mainform" v-if="seen">
            <div class="col">

                <form @submit="onSubmit" class="needs-validation vendor-mas">
                    <div class="row">
                        <div class="col-md-4 mb-3">
                            <label for="companyName" class="form-label">Company Name </label>
                            <input type="text" v-model="form.companyName" class="form-control has-validation"
                                id="companyName" placeholder="Company Name" max="100" maxlength="100" required
                                autocomplete="off">
                            <div v-if="errors.companyName" class="invalid-feedback">{{ errors.companyName }}</div>
                        </div>
                        <div class="col-md-4 mb-3">
                            <label for="contactPerson" class="form-label">Contact Person Name </label>
                            <input type="text" v-model="form.contactPerson" class="form-control has-validation"
                                id="contactPerson" placeholder="Contact Person Name" max="100" maxlength="100" required
                                autocomplete="off">
                            <div v-if="errors.contactPerson" class="invalid-feedback">{{ errors.contactPerson }}</div>
                        </div>


                        <div class="col-md-4 mb-3">
                            <label for="address1" class="form-label">Address Line 1 </label>
                            <input type="text" v-model="form.address1" class="form-control" id="address1"
                                placeholder="Address Line 1" max="100" maxlength="100" autocomplete="off">
                            <div v-if="errors.address1" class="invalid-feedback">{{ errors.address1 }}</div>
                        </div>
                        <div class="col-md-4 mb-3">
                            <label for="address2" class="form-label">Address Line 2</label>
                            <input type="text" v-model="form.address2" class="form-control" id="address2"
                                placeholder="Address Line 2" max="100" maxlength="100" autocomplete="off">
                            <div v-if="errors.address2" class="invalid-feedback">{{ errors.address2 }}</div>
                        </div>


                        <div class="col-md-4 mb-3">
                            <label for="cityId" class="form-label">City </label>
                            <input type="text" placeholder="Enter City name..." id="cityId" v-model="query"
                                @keyup="getData()" autocomplete="off" class="form-control input-lg" required />
                            <div class="panel-footer" v-if="search_data.length">
                                <ul class="list-group">
                                    <a href="#" class="list-group-item" v-for="data1 in search_data" :key="data1"
                                        @click="getName(data1.cityStateName, data1.id)">{{ data1.cityStateName }}</a>
                                </ul>
                            </div>
                            <div v-if="errors.cityId" class="invalid-feedback">{{ errors.cityId }}</div>
                        </div>
                        <div class="col-md-4 mb-3">
                            <label for="groupTags" class="form-label">Group Tags</label>
                            <Multiselect v-model="form.groupTags" id="groupTags" :options="options" mode="tags"
                                :searchable="true" :createTag="true" required />
                            <div v-if="errors.groupTags" class="invalid-feedback">{{ errors.groupTags }}</div>
                        </div>


                        <div class="col-md-4 mb-3">
                            <label for="mobileNo" class="form-label">Phone No </label>
                            <input type="text" v-model="form.mobileNo" class="form-control" id="mobileNo"
                                placeholder="mobileNo" required autocomplete="off">
                            <div v-if="errors.mobileNo" class="invalid-feedback">{{ errors.mobileNo }}</div>
                        </div>
                        <div class="col-md-4">
                            <label for="emailId" class="form-label">E-mail Id </label>
                            <input type="email" v-model="form.emailId" class="form-control" id="emailId"
                                placeholder="EmailId" required autocomplete="off">
                            <div v-if="errors.emailId" class="invalid-feedback">{{ errors.emailId }}</div>
                        </div>
                        <div class="btn-bottom">
                            <input type="submit" :value="btnText" class="btn  btn-primary" />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>

    <div class="container">
        <hr />
        <div class="table-responsive">
            <table class="table" ref="vendorTable">
                <thead>
                    <tr>
                        <th>#ID</th>
                        <th>Vendor Details</th>
                        <th>Contact Person</th>
                        <th>City</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="vendor in vendors" :key="vendor.vendorId">
                        <td>{{ vendor.vendorId }}</td>
                        <td><b>{{ vendor.companyName }}</b><br />

                            {{ vendor.groupTags }}<br />
                            {{ vendor.emailId }}<br />
                            {{ vendor.mobileNo }}</td>
                        <td>{{ vendor.contactPerson }}</td>
                        <td>{{ vendor.cityName }}</td>
                        <td> <button type="button" id="btnSubContactPerson" @click="loadSubContact(vendor.vendorId)"
                                class="btn btn-primary mb-3" data-bs-toggle="modal" data-bs-target="#subContactPerson">
                                Add Sub Contact
                            </button>
                            <button class="btn btn-success btn-sm" @click="editdata(vendor.vendorId)"><i
                                    class="fa fa-pencil" aria-hidden="true"></i>
                                Edit</button> <button class="btn btn-danger btn-sm"
                                @click="deletedata(vendor.vendorId)"><i class="fa fa-trash" aria-hidden="true"></i>
                                Delete</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="container" v-if="loading">
        <div class="row">
            <div class="col">
                <Spinner />
            </div>
        </div>
    </div>
    <!-- Sub Contact Person Modal -->
    <div class="modal fade" id="subContactPerson" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
        aria-labelledby="subContactPersonLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-scrollable modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-5" id="subContactPersonLabel">{{ companyName }}</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="container-fluid">
                        <div class="container mb-3">
                            <div class="row">
                                <div class="row">
                                    <div class="col-md-8">
                                        <h4>Sub Contact Person</h4>
                                    </div>
                                    <div class="col-md-4">
                                        <!-- <pre>{{ form }} {{ query }}</pre> -->
                                        <button class="btn btn-primary " v-if="!subseen" @click="subseen = !subseen"
                                            style="float: right;" id="btnAdd">
                                            <i class="fa fa-plus-circle" aria-hidden="true"></i> Add</button>
                                        <button class="btn btn-primary" v-if="subseen" @click="subseen = !subseen"
                                            style="float: right;" id="btnHide">
                                            <i class="fa fa-minus-circle" aria-hidden="true"></i>
                                            Hide</button>
                                    </div>
                                </div>

                            </div>

                            <div class="row" id="mainform" v-if="subseen">
                                <div class="col">
                                    <!-- {{ subForm }} -->
                                    <form @submit="onSubmitSub" class="needs-validation vendor-mas">
                                        <div class="row">
                                            <div class="col-md-12 mb-3">
                                                <input type="hidden" v-model="vendorId"
                                                    class="form-control has-validation" id="vendorId"
                                                    placeholder="Vendor Id" max="100" maxlength="100" disabled>

                                            </div>
                                            <div class="col-md-12 mb-3">
                                                <label for="subContactName" class="form-label">Contact Person Name
                                                </label>
                                                <input type="text" v-model="subForm.subContactName"
                                                    class="form-control has-validation" id="subContactName"
                                                    placeholder="Contact Person Name" max="100" maxlength="100" required
                                                    autocomplete="off">
                                                <div v-if="errorsSub.subContactName" class="invalid-feedback">{{
                                                    errorsSub.subContactName
                                                }}</div>
                                            </div>
                                            <div class="col-md-12 mb-3">
                                                <label for="phoneNoSub" class="form-label">Phone No </label>
                                                <input type="text" v-model="subForm.phoneNo" class="form-control"
                                                    id="phoneNoSub" placeholder="phoneNo" required autocomplete="off">
                                                <div v-if="errorsSub.phoneNo" class="invalid-feedback">{{
                                                    errorsSub.phoneNo
                                                }}</div>
                                            </div>
                                            <div class="col-md-12 mb-3">
                                                <label for="emailIdSub" class="form-label">E-mail Id </label>
                                                <input type="email" v-model="subForm.emailId" class="form-control"
                                                    id="emailIdSub" placeholder="EmailId" required autocomplete="off">
                                                <div v-if="errorsSub.emailId" class="invalid-feedback">{{ errorsSub.emailId }}
                                                </div>
                                            </div>
                                            <div class="btn-bottom">
                                                <input type="submit" :value="btnTextSub" class="btn  btn-primary" />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>

                        <div class="container">
                            <hr />
                            <div class="table-responsive">
                                <table class="table" ref="subContactTable">
                                    <thead>
                                        <tr>
                                            <th>#ID</th>
                                            <th>Contact Person</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="subContact in subContactPerson" :key="subContact.id">
                                            <td>{{ subContact.id }}</td>
                                            <td><b>{{ subContact.subContactName }}</b><br />

                                                {{ subContact.emailId }}<br />
                                                {{ subContact.phoneNo }}</td>

                                            <td><button class="btn btn-success btn-sm mb-3"
                                                    @click="editdataSub(subContact.id)"><i class="fa fa-pencil"
                                                        aria-hidden="true"></i>
                                                    Edit</button> <button class="btn btn-danger btn-sm"
                                                    @click="deletedataSub(subContact.id)"><i class="fa fa-trash"
                                                        aria-hidden="true"></i>
                                                    Delete</button> </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <!-- <button type="button" class="btn btn-primary">Understood</button> -->
                </div>
            </div>
        </div>
    </div>
    <!-- filter model end -->
</template>
<style src="@vueform/multiselect/themes/default.css">

</style>
<script>

import { City } from '../services/City';
import { Group } from '../services/Group';
import { VendorMaster } from '../services/VendorMaster';
import { SubContact } from '../services/SubContact';
import Multiselect from '@vueform/multiselect';
import Spinner from '../components/Spinner.vue';
import { useUserStore } from '../stores/userstore';
import $ from "jquery";
import dt from "datatables.net";
import dtSub from "datatables.net";

$(function () {
    //  alert('ok');
    // this.dt = $('#cityTable').DataTable();
});
export default {
    name: 'VendorMaster',
    components: {
        Multiselect,
        Spinner

    },
    setup() {
        const store = useUserStore();

        return {
            store
        }
    },
    data() {
        return {
            form: {
                id: '',
                companyName: '',
                contactPerson: '',
                address1: '',
                address2: '',
                cityId: '',
                groupTags: [],
                mobileNo: '',
                emailId: '',

            },
            subForm: {
                id: '',
                vendorId: '',
                contactPerson: '',
                mobileNo: '',
                emailId: '',

            },
            companyName: '',
            vendorId: '',
            value: null,
            options: [

            ],
            query: '',
            search_data: [],
            loading: false,
            cities: [],
            groups: [],
            vendors: [],
            subContactPerson: [],
            seen: false,
            subseen: false,
            dt: dt,
            dtSub: dtSub,
            btnText: 'Save',
            btnTextSub: 'Save',
            errors: [],
            errorsSub: []
        }
    },
    async created() {
        try {
            this.loading = true;
            let response = await Group.getGroups()
            // console.log(response.data);
            this.groups = response.data;
            this.options = this.groups.map(function (group) {
                return {
                    value: group.id,
                    label: group.name
                }
            })
            this.loading = false;
        }
        catch (error) {
            this.errors = error;
            this.loading = false;
        }
        try {
            this.loading = true;
            let response = await VendorMaster.getVendors();
            // console.log(response.data);
            this.vendors = response.data;
            this.loading = false
        }
        catch (error) {
            this.errors = error;
            this.loading = false;
        }

    },
    updated() {

    },
    mounted() {
        this.dt = $(this.$refs.vendorTable).DataTable();
        this.dtSub = $(this.$refs.subContactTable).DataTable();
    },
    watch: {
        vendors() {
            this.dt.destroy();
            this.$nextTick(() => {
                this.dt = $(this.$refs.vendorTable).DataTable()
            })
        },
        subContactPerson() {
            this.dtSub.destroy();
            this.$nextTick(() => {
                this.dtSub = $(this.$refs.subContactTable).DataTable()
            })
        }

    }, methods: {
        checkValidations() {
            var companyName = document.getElementById("companyName");
            var contactPerson = document.getElementById("contactPerson");
            var address1 = document.getElementById("address1");
            var address2 = document.getElementById("address2");
            var mobileNo = document.getElementById("mobileNo");
            var emailId = document.getElementById("emailId");
            var cityId = document.getElementById("cityId");
            var groupTags = document.getElementById("groupTags");

            if (this.errors.companyName) companyName.classList.add("is-invalid"); else companyName.classList.add("is-valid");
            if (this.errors.contactPerson) contactPerson.classList.add("is-invalid"); else contactPerson.classList.add("is-valid");
            if (this.errors.address1) address1.classList.add("is-invalid"); else address1.classList.add("is-valid");
            if (this.errors.address2) address2.classList.add("is-invalid"); else address2.classList.add("is-valid");
            if (this.errors.mobileNo) mobileNo.classList.add("is-invalid"); else mobileNo.classList.add("is-valid");
            if (this.errors.emailId) emailId.classList.add("is-invalid"); else emailId.classList.add("is-valid");
            if (this.errors.cityId) cityId.classList.add("is-invalid"); else cityId.classList.add("is-valid");
            if (this.errors.groupTags) groupTags.classList.add("is-invalid"); else groupTags.classList.add("is-valid");
        },
        removeValidations() {
            var companyName = document.getElementById("companyName");
            var contactPerson = document.getElementById("contactPerson");
            var address1 = document.getElementById("address1");
            var address2 = document.getElementById("address2");
            var mobileNo = document.getElementById("mobileNo");
            var emailId = document.getElementById("emailId");
            var cityId = document.getElementById("cityId");
            var groupTags = document.getElementById("groupTags");

            companyName.classList.remove("is-invalid");
            companyName.classList.remove("is-valid");
            contactPerson.classList.remove("is-invalid");
            contactPerson.classList.remove("is-valid");
            address1.classList.remove("is-invalid");
            address1.classList.remove("is-valid");
            address2.classList.remove("is-invalid");
            address2.classList.remove("is-valid");
            mobileNo.classList.remove("is-invalid");
            mobileNo.classList.remove("is-valid");
            emailId.classList.remove("is-invalid");
            emailId.classList.remove("is-valid");
            cityId.classList.remove("is-invalid");
            cityId.classList.remove("is-valid");
            groupTags.classList.remove("is-invalid");
            groupTags.classList.remove("is-valid");
        },
        async onSubmit(e) {
            e.preventDefault()
            if (!this.form.companyName) {
                alert('Please Enter Company name')
                return
            }



            // const response = await Country.createCountry(form);
            if (this.btnText === 'Save') {
                try {
                    this.loading = true;
                    const response = await VendorMaster.createVendor(this.form);
                    // console.log(response);
                    if (response.data.status == 'success') {
                        this.$swal(
                            'Vendor Created ',
                            response.data.status,
                            'success'
                        );
                    }
                    this.form.companyName = "";
                    this.form.contactPerson = "";
                    this.form.address1 = "";
                    this.form.address2 = "";
                    this.form.mobileNo = "";
                    this.form.emailId = "";
                    this.form.cityId = "";
                    this.query = "";
                    this.form.groupTags = [];
                    this.loading = false;
                    this.btnText = "Save";
                    this.removeValidations();
                } catch (error) {
                    // console.log("this is error ", error)
                    if (error.response.status === 422) {
                        // console.log(error.response.data.errors.name);
                        this.errors = error.response.data.errors;
                        this.checkValidations();

                    }
                    this.loading = false;
                    // console.log(errors.value.name);
                }
            } else {
                // alert('update');
                try {
                    this.loading = true;
                    const response = await VendorMaster.updateVendor(this.form, this.form.id);
                    // console.log(response);
                    if (response.data.status == 'success') {
                        this.$swal(
                            'Vendor Updated ',
                            response.data.status,
                            'success'
                        );
                    }
                    this.form.companyName = "";
                    this.form.contactPerson = "";
                    this.form.address1 = "";
                    this.form.address2 = "";
                    this.form.mobileNo = "";
                    this.form.emailId = "";
                    this.form.cityId = "";
                    this.query = "";
                    this.form.groupTags = [];
                    this.loading = false;
                    this.btnText = "Save";
                    this.removeValidations();

                } catch (error) {
                    // console.log("this is error ", error)
                    if (error.response.status === 422) {
                        // console.log(error.response.data.errors.name);
                        this.errors = error.response.data.errors;
                        this.checkValidations();
                    }
                    this.loading = false;
                    // console.log(errors.value.name);
                }

            }
            this.loading = true
            let response = await VendorMaster.getVendors();
            // console.log(response.data);
            this.vendors = response.data;
            this.loading = false;


        },
        async editdata(id) {
            // alert(id);
            this.loading = true;
            this.btnText = "Update";
            const response = await VendorMaster.getVendor(id);
            this.form.id = id;
            this.form.companyName = response.data.companyName;
            this.form.contactPerson = response.data.contactPerson;
            this.form.address1 = response.data.address1;
            this.form.address2 = response.data.address2;
            this.form.emailId = response.data.emailId;
            this.form.mobileNo = response.data.mobileNo;
            this.form.groupTags = response.data.groupTags.split(",");
            this.form.cityId = response.data.cityId;
            let cityName = await City.getCity(response.data.cityId);
            // console.log(cityName);
            this.query = cityName.data.cityStateName;

            // console.log(response.data.name);
            // this.btnText = "Save";
            this.seen = true;
            this.loading = false;
        },
        async loadSubContact(id) {
            // alert(id);
            this.loading = true;
            // this.btnText = "Update";
            const response = await VendorMaster.getVendor(id);
            this.vendorId = id;
            this.subForm.vendorId = id;
            this.companyName = response.data.companyName;

            let subResponse = await SubContact.getSubContacts(id);
            // alert(subResponse.data);
            // console.log("sub", subResponse);
            this.subContactPerson = subResponse.data;

            this.loading = false;
        },
        async deletedata(id) {
            let result = await this.$swal({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            });
            // alert(result.isConfirmed);
            if (result.isConfirmed) {
                this.loading = true;
                const resDel = await VendorMaster.deleteVendor(id)
                // console.log(resDel);
                if (resDel.status == 204) {
                    this.$swal(
                        'Vendors Deleted',
                        resDel.data.status,
                        'success'
                    );
                }

            }

            // alert(id);

            // this.loading = true;
            let response = await VendorMaster.getVendors();
            this.vendors = response.data;
            this.loading = false;
        },
        async getData() {
            try {


                this.loading = true;
                this.search_data = [];
                let data = {
                    "query": this.query
                }
                let response = await City.citySearch(data);
                // console.log(response.data.length);
                this.search_data = response.data;
                // console.log(this.search_data.length);
                this.loading = false;
            } catch (errors) {
                this.errors = errors.response.data.errors.query;
                this.loading = false;
                // this.$swal(
                //     'City Not Seleted',
                //     errors.response.data.errors.query,
                //     'error'
                // );
            }
        },
        getName: function (name, id) {
            this.query = name;
            this.form.cityId = id;
            this.search_data = [];
        },
        checkValidationsSub() {            
            var subContactName = document.getElementById("subContactName");            
            var phoneNoSub = document.getElementById("phoneNoSub");
            var emailIdSub = document.getElementById("emailIdSub");
            


            if (this.errorsSub.subContactName) subContactName.classList.add("is-invalid"); else subContactName.classList.add("is-valid");            
            if (this.errorsSub.phoneNoSub) phoneNoSub.classList.add("is-invalid"); else phoneNoSub.classList.add("is-valid");
            if (this.errorsSub.emailIdSub) emailIdSub.classList.add("is-invalid"); else emailIdSub.classList.add("is-valid");
            
        },
        removeValidationsSub() {            
            var subContactName = document.getElementById("subContactName");            
            var phoneNoSub = document.getElementById("phoneNoSub");
            var emailIdSub = document.getElementById("emailIdSub");
            

            
            subContactName.classList.remove("is-invalid");
            subContactName.classList.remove("is-valid");            
            phoneNoSub.classList.remove("is-invalid");
            phoneNoSub.classList.remove("is-valid");
            emailIdSub.classList.remove("is-invalid");
            emailIdSub.classList.remove("is-valid");
            
        },
        async onSubmitSub(e) {
            e.preventDefault()
            if (!this.subForm.subContactName) {
                alert('Please Enter Contact Person Name')
                return
            }



            // const response = await Country.createCountry(form);
            if (this.btnTextSub === 'Save') {
                try {
                    this.loading = true;
                    alert('save');
                    const response = await SubContact.createSubContact(this.subForm);
                    // console.log(response);
                    if (response.data.status == 'success') {
                        this.$swal(
                            'Sub Contact Created ',
                            response.data.status,
                            'success'
                        );
                    }
                    
                    this.subForm.contactPerson = "";                    
                    this.subForm.mobileNo = "";
                    this.subForm.emailId = "";                    
                    this.loading = false;
                    this.btnTextSub = "Save";
                    this.removeValidationsSub();
                } catch (error) {
                    // console.log("this is error ", error)
                    if (error.response.status === 422) {
                        // console.log(error.response.data.errors.name);
                        this.errorsSub = error.response.data.errors;
                        this.checkValidationsSub();

                    }
                    this.loading = false;
                    // console.log(errors.value.name);
                }
            } else {
                // alert('update');
                try {
                    this.loading = true;
                    const response = await SubContact.updateSubContact(this.subForm, this.subForm.id);
                    // console.log(response);
                    if (response.data.status == 'success') {
                        this.$swal(
                            'Sub Contact Updated ',
                            response.data.status,
                            'success'
                        );
                    }
                    
                    this.subForm.contactPerson = "";                    
                    this.subForm.mobileNo = "";
                    this.subForm.emailId = "";                    
                    this.loading = false;
                    this.btnTextSub = "Save";
                    this.removeValidationsSub();

                } catch (error) {
                    // console.log("this is error ", error)
                    if (error.response.status === 422) {
                        // console.log(error.response.data.errors.name);
                        this.errorsSub = error.response.data.errors;
                        this.checkValidationsSub();
                    }
                    this.loading = false;
                    // console.log(errors.value.name);
                }

            }
            this.loading = true
            let response = await SubContact.getSubContacts(this.vendorId);
            // console.log(response.data);
            this.subContactPerson = response.data;
            this.loading = false;


        },
        async editdataSub(id) {
            // alert(id);
            this.loading = true;
            this.btnTextSub = "Update";
            const response = await SubContact.getSubContact(id);
            this.subForm.id = id;            
            this.subForm.vendorId = response.data.vendorId;            
            this.subForm.subContactName = response.data.subContactName;            
            this.subForm.emailId = response.data.emailId;
            this.subForm.phoneNo = response.data.phoneNo;
            

            // console.log(response.data.name);
            // this.btnText = "Save";
            this.subseen = true;
            this.loading = false;
        },
        async deletedataSub(id) {
            let result = await this.$swal({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            });
            // alert(result.isConfirmed);
            if (result.isConfirmed) {
                this.loading = true;
                const resDel = await SubContact.deleteSubContact(id);
                // console.log(resDel);
                if (resDel.status == 204) {
                    this.$swal(
                        'Sub Contact Deleted',
                        resDel.data.status,
                        'success'
                    );
                }

            }

            // alert(id);

            // this.loading = true;
            let response = await SubContact.getSubContacts(this.vendorId);
            this.subContactPerson = response.data;
            this.loading = false;
        },
        hideFrom() {

        }
    },
}
</script>
<style scoped>
.vendor-mas {
    background: #fbfbfb;
    border: solid 1px #d2d2d2;
    padding: 30px;
    margin: 20px 0px;
}

.vendor-mas .form-control {
    padding: 12px 10px;
    border-radius: 0;

}

.multiselect {
    border-radius: 0;
    padding: 4px 10px;
}

.btn-bottom {
    text-align: center;
}

.vendor-mas .btn.btn-primary {
    border-radius: 3px;
    padding: 10px 25px;
    display: inline-block;
    font-size: 18px;
    font-weight: 700;
}

.table>thead {
    vertical-align: bottom;
    background: lightskyblue;
}

tbody tr td {
    border-right: 1px solid #a7daf9;
}

tbody tr td:first-child {
    border-left: 1px solid #a7daf9;
}


tr.odd {
    background-color: #FFF;
    vertical-align: middle;
}

tr.even {
    background-color: #e5f5ff;
    vertical-align: middle;
}
</style>