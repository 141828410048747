import axios from "./axios.js"

export class SubContact {

    static getSubContacts(vendorId) {
        let defaultURL = `/subcontactdetail/${vendorId}`;
        return axios.get(defaultURL);

    }
    static getSubContact(contactId) {
        let defaultURL = `/subcontact/${contactId}`;
        return axios.get(defaultURL);
    }
    static createSubContact(subContact) {
        let defaultURL = `/subcontact`;
        return axios.post(defaultURL, subContact);
    }
    static updateSubContact(subContact, contactId) {
        let defaultURL = `/subcontact/${contactId}`;
        return axios.put(defaultURL, subContact);
    }
    static deleteSubContact(contactId) {
        let defaultURL = `/subcontact/${contactId}`;
        return axios.delete(defaultURL);
    }

}